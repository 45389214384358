import React, {useContext, useEffect, useState} from 'react';
import {Link, useNavigate} from "react-router-dom";
import Container from "../components/Container";
import {Button, CircularProgress} from "@mui/material";
import ChevronLeftIcon from '@mui/icons-material/ChevronLeft';
import {ApiContext} from "../App";
import {useJWT} from "../contexts/AuthContextProvider";
import NameInput from "../components/NameInput";
import {AreaInterface} from "./Areas";
import AreaCard from "../components/AreaCard";
import MyAreaNeeds from "../components/MyAreaNeeds";
import StartAgainMapButton from "../components/StartAgainMapButton";
import GenerateMapButton from "../components/GenerateMapButton";
import {useMapOfNeeds} from "../contexts/MapOfNeedsProvider";

function MyMap() {
  const api = useContext(ApiContext);
  const {removeJWT, token} = useJWT();
  const navigate = useNavigate()
  const {id} = useMapOfNeeds();
  const [loading, setLoading] = useState<boolean>(true);
  const [areas, setAreas] = useState<Array<AreaInterface>>([]);

  useEffect(() => {
    fetch(api.url + "areas", {
      headers: {
        'Authorization': `Bearer ${token}`
      },
    }).then(res => {
      if (res.status === 401) {
        removeJWT();
        navigate("/logowanie");
        return;
      }
      return res.json()
    }).then((data: Array<AreaInterface>) => {
      setAreas(data);
      setLoading(false);
    });
  }, [])

  return (
    <Container>
      <div className="md:absolute left-10 top-20 max-md:mt-5">
        <Link to="/">
          <Button variant="contained" color="secondary">
            <ChevronLeftIcon/> Wróć do PESBOX PLANER
          </Button>
        </Link>
      </div>
      <div className="text-5xl max-md:font-bold max-md:text-2xl max-md:my-5 md:my-14 text-center">
        Moja mapa potrzeb
      </div>

      <NameInput/>

      {loading &&
        <div className="flex justify-center items-center my-20">
          <CircularProgress size={80}/>
        </div>
      }

      <div className="grid gap-8 max-md:gap-3 md:grid-cols-2 md:mb-14 mb-5 max-md:mx-0 md:mt-10 mt-5">
        {areas.map(area => {
          return <AreaCard area={area} key={area.id}>
            <MyAreaNeeds areaId={area.id}/>
          </AreaCard>
        })}
      </div>

      <div className="w-full md:mb-10 mb-4 flex gap-4">
        <div className="whitespace-nowrap">
          <StartAgainMapButton/>
        </div>
        <div className="flex-grow">
          <GenerateMapButton id={id}/>
        </div>
      </div>
    </Container>
  );
}

export default MyMap;

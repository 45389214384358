import React, {ChangeEvent} from 'react';
import {TextField, TextFieldProps} from "@mui/material";
import {FormValues} from "./Register";
import {FormikErrors} from "formik";

interface Props {
  values: FormValues;
  errors: FormikErrors<FormValues>
  handleChange: (e: ChangeEvent<any>) => void;
}

function IndividualRegisterForm({values, errors, handleChange}: Props) {
  const getInputProps = (name: keyof FormValues): TextFieldProps => {
    return {
      fullWidth: true,
      variant: "outlined",
      name: name,
      error: !!errors[name],
      helperText: errors[name],
      value: values[name],
      onChange: handleChange
    }
  }
  return (
    <div className="flex flex-col gap-6">
      <TextField label="Imię" {...getInputProps("firstName")}/>
      <TextField label="Nazwisko" {...getInputProps("lastName")}/>
      <TextField label="E-mail" {...getInputProps("username")}/>
      <TextField type="password" label="Hasło" {...getInputProps("password")}/>
      <TextField type="password" label="Powtórz hasło" {...getInputProps("confirmPassword")}/>
    </div>
  );
}

export default IndividualRegisterForm;

import React, {useContext, useEffect, useState} from 'react';
import {Link, useNavigate, useParams} from "react-router-dom";
import Container from "../components/Container";
import {Button, CircularProgress} from "@mui/material";
import ChevronLeftIcon from '@mui/icons-material/ChevronLeft';
import {ApiContext} from "../App";
import {useJWT} from "../contexts/AuthContextProvider";
import AreaButton from "../components/AreaButton";
import {AreaInterface} from "./Areas";
import CardCarousel from "../components/CardCarousel";

export interface Card {
  id: string;
  title: string;
  imageFront: string;
  imageBack: string;
  order: number;
  content: string | null;
}

function Area() {
  const api = useContext(ApiContext);
  const {removeJWT, token} = useJWT();
  const navigate = useNavigate()
  const [loading, setLoading] = useState<boolean>(true);
  const [areas, setAreas] = useState<Array<AreaInterface>>([]);
  const [area, setArea] = useState<AreaInterface | undefined>();
  const [nextArea, setNextArea] = useState<AreaInterface | undefined>();
  const {id} = useParams();

  const getCards = () => {
    fetch(api.url + "areas/" + id, {
      headers: {
        'Authorization': `Bearer ${token}`
      },
    }).then(res => {
      if (res.status === 401) {
        removeJWT();
        navigate("/logowanie");
        return;
      }
      return res.json()
    }).then((data: AreaInterface) => {
      setArea(data);
    });
  }

  useEffect(() => {
    fetch(api.url + "areas", {
      headers: {
        'Authorization': `Bearer ${token}`
      },
    }).then(res => {
      if (res.status === 401) {
        removeJWT();
        navigate("/logowanie");
        return;
      }
      return res.json()
    }).then((data: Array<AreaInterface>) => {
      setAreas(data);
      setLoading(false);
    });

    getCards()
  }, [])

  useEffect(() => {
    getCards();

    const areaIndex = areas.findIndex((el) => el.id === id);

    setNextArea(areas[areaIndex + 1]);
  }, [areas, id])

  return (
    <Container hideBg={true} limitSize={false}>
      <div className="px-5 min-h-[90vh]">
        <div className="md:flex flex-wrap p-5 items-center max-md:flex-col">
          <Link to="/potrzeby" className="mr-24 max-md:w-full">
            <Button variant="contained" color="secondary">
              <ChevronLeftIcon/> Wróć do podglądu obszarów
            </Button>
          </Link>
          <div className="flex-grow grid grid-cols-4 grid-rows-1 md:gap-4 gap-2">
            {areas.map(area => {
              return <AreaButton area={area} key={area.id}/>
            })}
          </div>
        </div>

        {area &&
          <div>
            <div className="text-center text-5xl mb-10">
              Obszar {area.order}. {area.name}
            </div>

            {!loading && area.cards.length > 0 &&
              <CardCarousel allCards={area.cards} area={area}/>
            }
            {!loading && area.cards.length == 0 &&
              <div className="text-center text-xl my-5">Nie dodano jeszcze żadnych kart do tego obszaru</div>
            }

            <div className="flex flex-col items-center my-5">
              {nextArea ?
                <Link to={"/potrzeby/" + nextArea.id}>
                  <Button variant="contained" style={{padding: "8px 50px"}}>Przejdź do następnego obszaru</Button>
                </Link> :
                <Link to="/moja-mapa">
                  <Button variant="contained" fullWidth>
                    Przejdź do mapy potrzeb
                  </Button>
                </Link>
              }
            </div>
          </div>
        }

        {!area || loading &&
          <div className="h-full w-full flex justify-center items-center">
            <CircularProgress size={80}/>
          </div>
        }
      </div>
    </Container>
  );
}

export default Area;

import React from 'react';
import {Card} from "../pages/Area";
import {AreaInterface} from "../pages/Areas";
import CancelIcon from '@mui/icons-material/Cancel';
import {IconButton} from "@mui/material";
import {useMapOfNeeds} from "../contexts/MapOfNeedsProvider";

interface Props {
  card?: Card
  area: AreaInterface;
  selected?: boolean;
  disabled?: boolean;
  showDeleteButton?: boolean
}

function NeedButton({card, area, selected, disabled, showDeleteButton}: Props) {
  const {cards, patchMapOfNeeds, name} = useMapOfNeeds();
  const {colorPrimary} = area

  if (!card) {
    return null
  }

  const handleRemove = (id: string) => {
    patchMapOfNeeds({
      name,
      cards: cards.filter(cardId => cardId !== id)
    })
  }

  return (
    <React.Fragment>
      <div style={{
        color: !selected ? area.colorSecondary : "white",
        backgroundColor: selected ? area.colorSecondary : "white",
        border: `2px solid ${area.colorSecondary}`,
        borderRadius: "4px",
        fontSize: 20,
        padding: 5,
        textAlign: "center",
        opacity: disabled ? 0.5 : 1,
        position: "relative"
      }}>
        {showDeleteButton &&
          <IconButton style={{
            position: "absolute",
            top: "-17px",
            right: "-17px",
            color: "#353535",
            opacity: 0.8,
          }}
                      onClick={() => card && handleRemove(card.id)}
          >
            <CancelIcon/>
          </IconButton>
        }

        {card.order}
      </div>
    </React.Fragment>
  );
}

export default NeedButton;
import React from 'react';
import logo from './resources/logo.svg';
import bg from './resources/bg3.svg';
import {Button, useMediaQuery, useTheme} from "@mui/material";
import {Link} from "react-router-dom";

function Error() {
  const theme = useTheme();
  const matches = useMediaQuery(theme.breakpoints.down("md"));

  return (
    <div
      className="md:w-[100vw] md:h-[100vh] flex flex-col justify-center items-center max-md:px-6 max-md:py-10"
      style={{
        backgroundImage: matches ? 'none' : `url(${bg})`,
        backgroundPosition: "center",
        backgroundRepeat: "no-repeat",
        backgroundSize: "1950px"
      }}>
      <div className="md:w-[750px] flex flex-col justify-center items-center text-base text-justify">
        <img src={logo} className="md:top-24 max-md:mb-5 md:mb-12"/>
        <div className="text-center">
          <div className="text-8xl">404</div>
          <div className="text-6xl">Nie znaleziono strony.</div>
          <div className="text-2xl">Strona o podanym adresie nie została znaleziona</div>

          <div className="flex justify-center items-center gap-[30px] mt-6 md:mt-[60px]">
            <Link to={`/`}>
              <Button variant="contained">Wróć do strony głównej</Button>
            </Link>
          </div>
        </div>
      </div>
    </div>
  );
}

export default Error;

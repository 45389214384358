import React from 'react';
import './App.css';
import './tw.css';
import {createBrowserRouter, RouterProvider,} from "react-router-dom";
import ErrorPage from "./error-page";
import Register from "./pages/Auth/Register";
import Login from "./pages/Auth/Login";
import Container from "./Template/Container";
import Areas from "./pages/Areas";
import {AuthProvider} from "./contexts/AuthContextProvider";
import Start from "./pages/Home/Start";
import ProtectedRoute from "./components/ProtectedRoute";
import {createTheme, ThemeProvider} from "@mui/material";
import Comments from "./pages/Comments";
import ChangePassword from "./pages/ChangePassword";
import {default as AuthChangePassword} from "./pages/Auth/ChangePassword";
import Area from "./pages/Area";
import MyMap from "./pages/MyMap";
import GenerateMap from "./pages/GenerateMap";
import ForgotPassword from "./pages/Auth/ForgotPassword";

const getApiUrl = process.env.NODE_ENV === "development" ? process.env.REACT_APP_DEV_API_URL : process.env.REACT_APP_PROD_API_URL;

export const ApiContext = React.createContext({
  url: getApiUrl,
});

const router = createBrowserRouter([
  {
    path: "/",
    element: <Container/>,
    errorElement: <ErrorPage/>,
    children: [
      {
        path: "",
        element: <Start/>,
      },
      {
        path: "rejestracja",
        element: <Register/>,
      },
      {
        path: "logowanie",
        element: <Login/>,
      },
      {
        path: "zapomnialem-hasla",
        element: <ForgotPassword/>,
      },
      {
        path: "zapomnialem-hasla/:token",
        element: <AuthChangePassword/>,
      },
      {
        path: "potrzeby",
        element: <ProtectedRoute><Areas/></ProtectedRoute>,
      },
      {
        path: "potrzeby/:id",
        element: <ProtectedRoute><Area/></ProtectedRoute>,
      },
      {
        path: "uwagi",
        element: <ProtectedRoute><Comments/></ProtectedRoute>,
      },
      {
        path: "zmien-haslo",
        element: <ProtectedRoute><ChangePassword/></ProtectedRoute>,
      },
      {
        path: "moja-mapa",
        element: <ProtectedRoute><MyMap/></ProtectedRoute>,
      },
      {
        path: "generuj",
        element: <ProtectedRoute><GenerateMap/></ProtectedRoute>,
      },
    ],
  },
]);

const theme = createTheme({
  palette: {
    secondary: {
      main: '#E0E0E0'
    }
  }
});

function App() {
  return (
    <ThemeProvider theme={theme}>
      <ApiContext.Provider value={{url: getApiUrl}}>
        <AuthProvider>
          <RouterProvider router={router}/>
        </AuthProvider>
      </ApiContext.Provider>
    </ThemeProvider>
  );
}

export default App;

import React, {useContext, useState} from 'react';
import {ApiContext} from "../App";
import {useJWT} from "../contexts/AuthContextProvider";
import {LoadingButton} from "@mui/lab";

interface Props {
  id: string
}

function GenerateMapButton({id}: Props) {
  const api = useContext(ApiContext);
  const {token} = useJWT();
  const [loading, setLoading] = useState<boolean>(false);

  const generate = () => {
    setLoading(true);
    fetch(`${api.url}map_of_needs/${id}/_generate`, {
      headers: {
        'Authorization': `Bearer ${token}`
      },
    })
      .then(res => res.blob())
      .then(blob => {
        const url = window.URL.createObjectURL(blob);
        const a = document.createElement('a');
        a.href = url;
        a.download = "moja_mapa.pdf";
        document.body.appendChild(a); // we need to append the element to the dom -> otherwise it will not work in firefox
        a.click();
        a.remove();
        setLoading(false);
      });
  }

  return (
    <LoadingButton
      loading={loading}
      fullWidth
      loadingPosition="start"
      variant="contained"
      onClick={generate}
    >
      Pobierz mapę potrzeb
    </LoadingButton>
  );
}

export default GenerateMapButton;

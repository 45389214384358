import React, {useContext, useEffect, useState} from 'react';
import {Button, CircularProgress} from "@mui/material";
import {ApiContext} from "../App";
import {useJWT} from "../contexts/AuthContextProvider";
import {AreaInterface} from "../pages/Areas";
import {Card} from "../pages/Area";
import {useMapOfNeeds} from "../contexts/MapOfNeedsProvider";
import {Link} from "react-router-dom";

interface Props {
  areaId: string
}

function MyAreaNeeds({areaId}: Props) {
  const api = useContext(ApiContext);
  const {cards} = useMapOfNeeds();
  const {token} = useJWT();
  const [selectedCards, setSelectedCards] = useState<Card[]>([])
  const [loading, setLoading] = useState<boolean>(true);

  useEffect(() => {
    fetch(api.url + "areas/" + areaId, {
      headers: {
        'Authorization': `Bearer ${token}`
      },
    }).then(res => {
      return res.json()
    }).then((data: AreaInterface) => {
      setSelectedCards(data.cards.filter(card => cards.includes(card.id)))
      setLoading(false)
    });
  }, [])

  return (
    <div className="p-4 text-left flex flex-col justify-between h-full">
      {loading &&
        <div className="flex justify-center items-center">
          <CircularProgress size={80}/>
        </div>
      }
      {!loading && !selectedCards.length && <div className="text-center">Nie wybrałeś żadnych kart z tego obszaru</div>}
      <div>
        {selectedCards.map(el => {
          return <div className="grid" style={{gridTemplateColumns: "max-content 1fr"}}>
            <div className="mb-1" key={el.id}>Karta {el.order} -&nbsp;</div>
            <div className="mb-1" key={el.id}>{el.title}</div>
          </div>
        })}
      </div>

      <div className="mt-3 text-center">
        <Link to={`/potrzeby/${areaId}`}>
          <Button variant="contained" color="secondary">Edytuj</Button>
        </Link>
      </div>
    </div>
  );
}

export default MyAreaNeeds;

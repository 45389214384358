import React, {createContext, ReactNode, useContext, useEffect, useState} from 'react';
import {ApiContext} from "../App";
import axios from "axios";

interface JWTContextInterface {
  token: string | null;
  email: string;
  setEmail: (email: string) => void;
  password: string;
  setPassword: (password: string) => void;
  setJWT: (jwt: string) => void;
  removeJWT: () => void;
  login: (email: string, password: string) => Promise<void>;
  resetPassword: (email: string) => Promise<boolean>;
  changePassword: (token: string, password: string) => Promise<boolean>;
}

const JWTContext = createContext<JWTContextInterface>({
  token: null,
  email: '',
  setEmail: () => {
  },
  password: '',
  setPassword: () => {
  },
  setJWT: () => {
  },
  removeJWT: () => {
  },
  login: () => Promise.resolve(),
  resetPassword: () => Promise.resolve(false),
  changePassword: () => Promise.resolve(false),
});

interface Props {
  children: ReactNode
}

const AuthProvider = ({children}: Props) => {
  const [token, setToken] = useState<string | null>(localStorage.getItem("jwt"));
  const [email, setEmail] = useState('');
  const [password, setPassword] = useState('');
  const api = useContext(ApiContext);

  const loginUrl = api.url + 'auth/login';
  const resetPasswordUrl = api.url + 'auth/reset-password'

  const setJWT = (jwt: string) => {
    setToken(jwt);
    localStorage.setItem('jwt', jwt);
  };

  const removeJWT = () => {
    setToken(null);
    localStorage.removeItem('jwt');
  };

  const refreshJWT = () => {
    login(email, password).then((response: any) => {
      if (response.code && response.code >= 300) {
        removeJWT();
        return;
      }
      setJWT(response.token);
    })
  };

  const login = async (email: string, password: string): Promise<any> => {
    return await axios.post(loginUrl, {
      username: email,
      password,
    })
      .then((response) => {
        return response;
      })
      .catch((error) => {
        return error;
      });
  };

  const resetPassword = async (email: string): Promise<boolean> => {
    return await fetch(resetPasswordUrl, {
      method: 'POST',
      headers: {
        'accept': 'application/json',
        'Content-Type': 'application/json',
      },
      body: JSON.stringify({email}),
    }).then(res => {
      return res.status === 204
    });
  };

  const changePassword = async (token: string, password: string): Promise<boolean> => {
    return await fetch(`${resetPasswordUrl}/${token}`, {
      method: 'PATCH',
      headers: {
        'accept': 'application/json',
        'Content-Type': 'application/json',
      },
      body: JSON.stringify({newPassword: password}),
    }).then(res => {
      return res.status === 200
    });
  };

  // TODO coś nie działa
  useEffect(() => {
    const interval = setInterval(refreshJWT, 3600000); // odświeżaj co godzinę
    return () => clearInterval(interval);
  }, []);

  return (
    <JWTContext.Provider
      value={{token, setJWT, removeJWT, email, setEmail, password, setPassword, login, resetPassword, changePassword}}
    >
      {children}
    </JWTContext.Provider>
  );
};

const useJWT = (): JWTContextInterface => {
  const context = React.useContext(JWTContext);
  if (context === undefined) {
    throw new Error('useJWT must be used within a JWTProvider');
  }
  return context;
};

export {AuthProvider, useJWT};

import React, {ReactNode} from 'react';
import "./HomeCard.css"
import {Navigate} from "react-router-dom";
import {useJWT} from "../contexts/AuthContextProvider";

interface Props {
  children: ReactNode
}

function ProtectedRoute({children}: Props) {
  const {token} = useJWT();

  if (token) {
    return <React.Fragment>{children}</React.Fragment>;
  }
  return <Navigate to="/logowanie"/>
}

export default ProtectedRoute;

import React, {ReactNode, useContext} from 'react';
import {AreaInterface} from "../pages/Areas";
import {ApiContext} from "../App";

interface Props {
  area: AreaInterface
  children?: ReactNode
}

function AreaCard({area, children}: Props) {
  const api = useContext(ApiContext);

  return (
    <div className="text-center rounded-2xl p-3 flex flex-col" style={{
      backgroundColor: area.colorSecondary,
    }}>
      <div className="flex items-center">
        <img src={`${api.url}uploads/${area.icon}`} height={70} width={70} alt=""/>
        <div className="flex-grow font-black text-white text-xl uppercase tracking-wide">
          {area.name}
        </div>
      </div>
      <div className="text-white mt-2">Potrzeby</div>
      <div className="bg-white rounded-2xl mt-1 flex-grow">
        {children}
      </div>
    </div>
  );
}

export default AreaCard;

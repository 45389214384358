import React from 'react';
import {AreaInterface} from "../pages/Areas";
import {Link, useParams} from "react-router-dom";

interface Props {
  area: AreaInterface
}

function AreaButton({area}: Props) {
  let {id} = useParams();

  return (
    <Link to={`/potrzeby/${area.id}`}>
      <div className={(id !== area.id ? "opacity-30 " : " ") + "text-center w-full"}>
        <div className="border-b-[6px] p-2" style={{borderColor: area.colorPrimary}}>Obszar {area.order}</div>
        <div className="p-2 text-ellipsis overflow-hidden whitespace-nowrap">{area.name}</div>
      </div>
    </Link>
  );
}

export default AreaButton;

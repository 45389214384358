import React from 'react';
import HomeCard from "../../components/HomeCard";
import Container from "../../components/Container";
import {Button} from "@mui/material";

function Home() {
  return (
    <Container>
      <div className="text-5xl max-md:font-bold max-md:text-2xl max-md:my-8 my-14 text-center">Mój PESBOX PLANER</div>

      <div className="grid gap-14 max-md:gap-3 md:grid-cols-2 mb-14 max-md:m-3 max-md:mb-0">
        <HomeCard colorPrimary="#ea4f5f" colorSecondary="#B5394A" title="Zbadaj potrzeby"
                  description="Tu możesz zbadać swoje potrzeby rozwojowe w 4 obszarach biznesu" url="/potrzeby"/>
        <HomeCard colorPrimary="#C38604" colorSecondary="#B8850F" title="Moja Mapa Potrzeb"
                  description="Tu możesz pobrać do wydruku swoją mapę potrzeb" url="/moja-mapa"/>
      </div>
      <div className="grid gap-14 max-md:gap-3 md:grid-cols-2 mb-14 max-md:m-3">
        <HomeCard colorPrimary="#009994" colorSecondary="#008D89" title="Generator Mapy Potrzeb"
                  description="Tu możesz przenieść  numery wybranych kart aby pobrać swoją mapę potrzeb. Dla użytkowników kart papierowych."
                  url="/generuj"/>
        <HomeCard colorPrimary="#004972" colorSecondary="#0F2B47" title="Formularz kontaktowy"
                  description="Tu możesz zgłosić swoje uwagi z testowania, szczególne potrzeby oraz pomysły na zmiany"
                  url="/uwagi"/>

        <a href="/Instrukcja_pesbox_planer.pdf" target="_blank">
          <Button fullWidth variant="contained" color="primary">Zobacz instrukcje PESBOX PLANER (PDF)</Button>
        </a>
        <a href="/Instrukcja_serwisu_PESBOX_PLANER.pdf" target="_blank">
          <Button fullWidth variant="contained" color="primary">Jak korzystać z serwisu? (PDF)</Button>
        </a>
      </div>
    </Container>
  );
}

export default Home;

import React, {useContext} from 'react';
import {Card} from "../pages/Area";
import {ApiContext} from "../App";

interface Props {
  card: Card;
  style?: React.CSSProperties
  handleOpen: () => void;
  isMiddle: boolean
}

function CardComponent({card, style, handleOpen, isMiddle}: Props) {
  const api = useContext(ApiContext);

  return (
    <React.Fragment>
      <div style={style} onClick={handleOpen}>
        <div className="bg-white rounded-2xl">
          <img src={`${api.url}uploads/${card.imageFront}`}
               alt={isMiddle ? card.title + ". " + card.content || "" : ""}
               style={{
                 filter: !isMiddle ? "opacity(0.5)" : undefined
               }
               }/>
        </div>
      </div>
    </React.Fragment>
  );
}

export default CardComponent;
import React, {useContext, useState} from 'react';
import HeroLogo from "../../Template/HeroLogo";
import {Alert, TextField} from "@mui/material";
import {Link, useNavigate, useSearchParams} from "react-router-dom";
import {ApiContext} from "../../App";
import {useJWT} from "../../contexts/AuthContextProvider";
import {LoadingButton} from "@mui/lab";

function Login() {
  const [email, setUserEmail] = useState<string>('');
  const [emailError, setEmailError] = useState<string | undefined>(undefined);
  const [password, setUserPassword] = useState<string>('');
  const [passwordError, setPasswordError] = useState<string | undefined>(undefined);
  const [generalError, setGeneralError] = useState<string | undefined>(undefined);
  const [loading, setLoading] = useState(false);

  const api = useContext(ApiContext);
  const {setJWT, setEmail, setPassword, login} = useJWT();
  const navigate = useNavigate();
  let [searchParams] = useSearchParams();

  const handleEmailChange = (event: React.ChangeEvent<HTMLInputElement>) => {
    setUserEmail(event.target.value);
    setEmailError(undefined);
    setGeneralError(undefined);
  };

  const handlePasswordChange = (event: React.ChangeEvent<HTMLInputElement>) => {
    setUserPassword(event.target.value);
    setPasswordError(undefined);
    setGeneralError(undefined);
  };

  const handleKeyDown = (event: React.KeyboardEvent<HTMLInputElement>) => {
    if (event.key === "Enter") {
      handleSubmit();
    }
  }

  const handleSubmit = () => {
    setLoading(true);
    setGeneralError(undefined);
    if (!email) {
      setEmailError("Wpisz adres e-mail");
    }
    if (!password) {
      setPasswordError("Wpisz hasło");
    }

    if (emailError || passwordError) {
      setLoading(false);
      return;
    }

    if (!api.url || !email || !password) {
      setGeneralError("Wystąpił błąd, spróbuj ponownie później");
      setLoading(false);
      return;
    }

    login(email, password).then((response: any) => {
      if (response.request.status >= 300) {
        setGeneralError(response.response.data?.message || response.message);
        setLoading(false);
        return;
      }

      setJWT(response.data.token);
      setEmail(email);
      setPassword(password);
      setLoading(false);

      navigate("/")
    })
  }

  return (
    <React.Fragment>
      <HeroLogo/>
      <div
        className="w-full md:w-[60vw] flex flex-col md:justify-center items-center max-md:my-10 max-md:mx-5">
        <div className="w-full md:w-8/12">
          {searchParams.has("created") &&
            <Alert className="mb-4" severity="success">Twoje konto zostało utworzone, możesz się teraz zalogować</Alert>
          }
          <h2 className="text-2xl mb-10">Zaloguj się</h2>

          <div className="w-full">
            <TextField fullWidth
                       label="Adres e-mail"
                       name="username"
                       variant="outlined"
                       error={!!emailError}
                       helperText={emailError}
                       value={email}
                       onChange={handleEmailChange}
                       onKeyDown={handleKeyDown}
            />
          </div>
          <div className="w-full mt-6">
            <TextField fullWidth
                       type="password"
                       label="Hasło"
                       name="password"
                       variant="outlined"
                       error={!!passwordError}
                       helperText={passwordError}
                       value={password}
                       onChange={handlePasswordChange}
                       onKeyDown={handleKeyDown}
            />
          </div>
          <div className="text-right mt-2">
            <Link to="/zapomnialem-hasla" className="text-[#1C4F76]">Zapomniałeś hasła?</Link>
          </div>
          {generalError &&
            <Alert className="mt-2" severity="error">{generalError}</Alert>
          }
          <div className="mt-4">
            <LoadingButton
              loading={loading}
              fullWidth
              loadingPosition="start"
              variant="contained"
              onClick={handleSubmit}
            >
              Zaloguj się
            </LoadingButton>
          </div>
          <div className="mt-8 text-center">
            Nie znasz platformy PESBOX PLANER? {" "}
            <Link to={`/rejestracja`} className="text-[#1C4F76]">Zarejestruj się</Link>
          </div>
        </div>
      </div>
    </React.Fragment>
  );
}

export default Login;

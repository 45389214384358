import React, {useContext, useEffect, useState} from 'react';
import {AreaInterface} from "../pages/Areas";
import {ApiContext} from "../App";
import {useJWT} from "../contexts/AuthContextProvider";
import {CircularProgress} from "@mui/material";
import NeedButton from "./NeedButton";
import {useMapOfNeeds} from "../contexts/MapOfNeedsProvider";

interface Props {
  areaId: string
}

function GenerateMapButtons({areaId}: Props) {
  const api = useContext(ApiContext);
  const {token} = useJWT();
  const {patchMapOfNeeds, cards, name} = useMapOfNeeds();
  const [area, setArea] = useState<AreaInterface>()
  const [loading, setLoading] = useState<boolean>(true);
  const [needsLoading, setNeedsLoading] = useState<boolean>(false);

  const handleFavorite = (id: string) => {
    setNeedsLoading(true);
    if (cards.includes(id)) {
      patchMapOfNeeds({
        name,
        cards: cards.filter(el => el !== id)
      }).then(() => {
        setNeedsLoading(false);
      })
    } else {
      patchMapOfNeeds({
        name,
        cards: [...cards, id]
      }).then(() => {
        setNeedsLoading(false);
      })
    }
  }

  useEffect(() => {
    fetch(api.url + "areas/" + areaId, {
      headers: {
        'Authorization': `Bearer ${token}`
      },
    }).then(res => {
      return res.json()
    }).then((data: AreaInterface) => {
      setArea(data)
      setLoading(false)
    });
  }, [])

  return (
    <div className="py-4">
      {loading &&
        <div className="flex justify-center items-center">
          <CircularProgress size={80}/>
        </div>
      }

      {!loading && (!area || !area.cards.length) &&
        <div className="text-center">Nie dodano jeszcze żadnych kart do tego obszaru</div>
      }

      <div className="grid grid-cols-5 gap-x-2 gap-y-4 max-w-[220px] mx-auto">
        {area && area.cards.map(el => {
          return <button onClick={() => handleFavorite(el.id)} disabled={needsLoading} key={el.id}>
            <NeedButton area={area} card={el} selected={cards.includes(el.id)} disabled={needsLoading}/>
          </button>
        })}
      </div>
    </div>
  );
}

export default GenerateMapButtons;

import React, {useContext, useEffect, useState} from 'react';
import logo from '../resources/logo.svg';
import '../pages/Home/Welcome.css';
import {Link, useNavigate} from "react-router-dom";
import {ApiContext} from "../App";
import {useJWT} from "../contexts/AuthContextProvider";
import {Button, Menu, MenuItem} from '@mui/material';
import ExpandMoreIcon from '@mui/icons-material/ExpandMore';
import ExpandLessIcon from '@mui/icons-material/ExpandLess';
import MenuIcon from '@mui/icons-material/Menu';
import {useMapOfNeeds} from "../contexts/MapOfNeedsProvider";
import axios from "axios";

interface UserInterface {
  id: string;
  email: string;
  firstName: string;
  lastName: string;
  type: "user" | "organisation";
  organisation: string | null;
  address: string | null;
  postalCode: string | null;
  city: string | null;
}

function Header() {
  const api = useContext(ApiContext);
  const {removeJWT, token} = useJWT();
  const navigate = useNavigate();
  const {fetchLatest, createMapOfNeeds} = useMapOfNeeds();
  const [user, setUser] = useState<UserInterface | undefined>();
  const [anchorEl, setAnchorEl] = React.useState<null | HTMLElement>(null);
  const open = Boolean(anchorEl);
  const handleClick = (event: React.MouseEvent<HTMLButtonElement>) => {
    setAnchorEl(event.currentTarget);
  };
  const handleClose = () => {
    setAnchorEl(null);
  };

  const logout = () => {
    if (token) {
      removeJWT();
      navigate("/logowanie");
    }
  }

  const handleChangePassword = () => {
    navigate("/zmien-haslo");
    setAnchorEl(null);
  }

  const handleLogout = () => {
    logout();
    setAnchorEl(null);
  }

  useEffect(() => {
    if (token) {
      axios.get(api.url + "profile", {
        headers: {
          'Authorization': `Bearer ${token}`
        },
      })
        .then(function (response) {
          setUser(response.data);
          fetchLatest()
        })
        .catch(() => {
          logout();
        });
    }
  }, [token])

  return (
    <header className="header border-b border-black py-3.5 px-2 z-10 bg-white flex justify-between">
      <Link to="/" className="w-auto">
        <img src={logo} className="h-8" role="img" aria-label="Logo PESBOX PLANER"/>
      </Link>

      {token && user &&
        <div className="border-l border-black -my-3.5 flex justify-center items-center">
          <Button
            aria-controls={open ? 'basic-menu' : undefined}
            aria-haspopup="true"
            aria-expanded={open ? 'true' : undefined}
            onClick={handleClick}
            style={{
              padding: "5px 30px",
              color: "black"
            }}
          >
            <div className="max-md:hidden">
              {user.firstName} {user.lastName} {open ? <ExpandLessIcon/> : <ExpandMoreIcon/>}
            </div>
            <div className="md:hidden">
              <MenuIcon/>
            </div>
          </Button>
          <Menu
            id="basic-menu"
            anchorEl={anchorEl}
            open={open}
            onClose={handleClose}
            MenuListProps={{
              'aria-labelledby': 'basic-button',
            }}
          >
            <MenuItem onClick={handleChangePassword}>Zmień hasło</MenuItem>
            <MenuItem onClick={handleLogout}>Wyloguj</MenuItem>
          </Menu>
        </div>
      }
    </header>
  );
}

export default Header;

import React, {useContext, useState} from 'react';
import {Button, Dialog} from "@mui/material";
import {ApiContext} from "../App";
import {useJWT} from "../contexts/AuthContextProvider";
import {useMapOfNeeds} from "../contexts/MapOfNeedsProvider";

function StartAgainMapButton() {
  const api = useContext(ApiContext);
  const {startAgainMapOfNeeds} = useMapOfNeeds();
  const {token} = useJWT();
  const [loading, setLoading] = useState<boolean>(false);
  const [open, setOpen] = React.useState(false);

  const handleClickOpen = () => {
    setOpen(true);
  };

  const handleClose = () => {
    setOpen(false);
  };

  const reset = () => {
    startAgainMapOfNeeds().then(() => handleClose())
  }

  return (
    <React.Fragment>
      <Button variant="contained" color="error" onClick={handleClickOpen} disabled={loading}>
        Zacznij od nowa
      </Button>

      <Dialog
        open={open}
        onClose={handleClose}
        aria-labelledby="alert-dialog-title"
        aria-describedby="alert-dialog-description"
      >
        <div className="px-4 py-6">
          <div className="text-xl font-bold">Czy chcesz zacząć od nowa?</div>
          <div className="text-neutral-700">Nazwa oraz wybrane karty zostaną usunięte.</div>
          <div className="flex justify-between mt-5">
            <Button variant="contained" color="error" onClick={reset}>Zaczynam od nowa</Button>
            <Button variant="contained" onClick={handleClose} autoFocus>
              Anuluj
            </Button>
          </div>
        </div>
      </Dialog>
    </React.Fragment>
  );
}

export default StartAgainMapButton;

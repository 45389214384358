import React from 'react';
import {Outlet, useLocation} from "react-router-dom";
import Header from "./Header";
import Footer from "./Footer";
import {useJWT} from "../contexts/AuthContextProvider";
import {MapOfNeedsProvider} from "../contexts/MapOfNeedsProvider";
import {CookieConsent} from "react-cookie-consent";

function Container() {
  const {pathname} = useLocation()
  const {token} = useJWT();

  if (pathname == "/" && !token) {
    return <Outlet/>
  }
  return (
    <div className="min-h-screen flex flex-col">
      <MapOfNeedsProvider>
        <Header/>
        <main className="flex grow">
          <Outlet/>
        </main>
        <Footer/>
        <CookieConsent
          buttonText="Zamknij"
          cookieName="cookie-consent"
          style={{background: "#2B373BAA"}}
          buttonStyle={{color: "white", backgroundColor: "#1976d2"}}
        >
          Ta strona używa plików cookie w celu usprawnienia i ułatwienia dostępu do serwisu oraz prowadzenia
          danych statystycznych. Dalsze korzystanie z tej witryny oznacza akceptację tego stanu rzeczy.
        </CookieConsent>
      </MapOfNeedsProvider>
    </div>
  );
}

export default Container;

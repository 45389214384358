import React, {useContext, useState} from 'react';
import {Card} from "../pages/Area";
import ChevronLeftIcon from '@mui/icons-material/ChevronLeft';
import ChevronRightIcon from '@mui/icons-material/ChevronRight';
import {Button, CircularProgress, Modal} from "@mui/material";
import CloseIcon from '@mui/icons-material/Close';
import {ApiContext} from "../App";
import FavoriteBorderIcon from '@mui/icons-material/FavoriteBorder';
import {useMapOfNeeds} from "../contexts/MapOfNeedsProvider";
import FavoriteIcon from '@mui/icons-material/Favorite';
import {TransformComponent, TransformWrapper} from "react-zoom-pan-pinch";
import ZoomInIcon from '@mui/icons-material/ZoomIn';
import ZoomOutIcon from '@mui/icons-material/ZoomOut';

interface Props {
  selected: boolean;
  selectedCard: Card;
  nextHandler: () => void;
  backHandler: () => void;
  handleClose: () => void;
  open: boolean;
}

function CardModal({selected, selectedCard, nextHandler, backHandler, handleClose, open}: Props) {
  const [showBack, setShowBack] = useState(false);
  const [loaded, setLoaded] = useState(false);
  const {patchMapOfNeeds, cards, name} = useMapOfNeeds();

  const api = useContext(ApiContext);

  const handleFlip = () => setShowBack(!showBack);

  const handleNext = () => {
    nextHandler()
    setShowBack(false);
  };

  const handleBack = () => {
    backHandler()
    setShowBack(false);
  };

  const handleFavorite = () => {
    if (selected) {
      patchMapOfNeeds({
        name,
        cards: cards.filter(el => el !== selectedCard.id)
      })
    } else {
      patchMapOfNeeds({
        name,
        cards: [...cards, selectedCard.id]
      })
    }
  }

  React.useEffect(() => {
    setShowBack(false);
  }, [open]);

  return (
    <Modal
      open={open}
      onClose={handleClose}
    >
      <div className="mx-auto w-[65vh] max-w-[100vw] h-full">
        <TransformWrapper
          initialScale={1}
        >
          {({zoomIn, zoomOut, resetTransform, ...rest}) => (
            <div className="flex flex-col h-full justify-center items-center">
              <div className="flex my-3 justify-between w-[59vh] max-w-[100vw] px-2">
                <div className="flex gap-3">
                  <Button variant="contained" color="secondary" onClick={() => zoomOut()} aria-label="Pomniejsz">
                    <ZoomOutIcon/>
                  </Button>
                  <Button variant="contained" color="secondary" onClick={() => zoomIn()} aria-label="Powiększ">
                    <ZoomInIcon/>
                  </Button>
                </div>
                <div className="text-right">
                  <Button variant="contained" color="secondary" onClick={handleClose}>
                    Zamknij <CloseIcon className="ml-3"/>
                  </Button>
                </div>
              </div>
              <div className="flex-grow">
                <TransformComponent wrapperStyle={{height: "100%"}} contentStyle={{height: "100%"}}>
                  {!loaded &&
                    <div className="bg-white rounded-3xl flex justify-center items-center w-full"
                         style={{
                           aspectRatio: "0.73 / 1",
                         }}>
                      <CircularProgress size={80}/>
                    </div>
                  }
                  <img src={`${api.url}uploads/${showBack ? selectedCard.imageBack : selectedCard.imageFront}`}
                       alt={selectedCard.title + ". " + selectedCard.content || ""}
                       style={{
                         display: loaded ? "block" : "none",
                       }}
                       className="h-full"
                       onLoad={() => {
                         setLoaded(true)
                       }}
                  />
                </TransformComponent>
              </div>
              <div className="flex gap-2 my-3 max-w-[100vw] px-2">
                <Button variant="contained" color="secondary" onClick={handleBack} disabled={!loaded}
                        aria-label="Poprzednia karta">
                  <ChevronLeftIcon/>
                </Button>

                <Button variant="contained" onClick={handleFlip} disabled={!loaded}>Odwróć kartę</Button>

                <Button variant="contained" color="error" onClick={handleFavorite} disabled={!loaded}>
                  {selected ? "Usuń kartę" : "Dodaj kartę"}
                  {selected ? <FavoriteIcon className="ml-1"/> : <FavoriteBorderIcon className="ml-1"/>}
                </Button>

                <Button variant="contained" color="secondary" onClick={handleNext} disabled={!loaded}
                        aria-label="Następna karta">
                  <ChevronRightIcon/>
                </Button>
              </div>
            </div>
          )}
        </TransformWrapper>
      </div>
    </Modal>
  );
}

export default CardModal;
import React, {createContext, ReactNode, useContext, useState} from 'react';
import {ApiContext} from "../App";
import {useJWT} from "./AuthContextProvider";
import {useNavigate} from "react-router-dom";

export interface MapOfNeedsInterface {
  id?: string;
  name?: string;
  cards: string[];
  downloaded?: boolean;
}

interface MapOfNeedsContextInterface {
  id: string;
  name: string;
  cards: string[];
  downloaded: boolean;
  fetchLatest: () => Promise<any>;
  createMapOfNeeds: (data: MapOfNeedsInterface) => Promise<any>;
  patchMapOfNeeds: (data: MapOfNeedsInterface) => Promise<any>;
  setId: (data: string) => void;
  setName: (data: string) => void;
  setCards: (data: string[]) => void;
  setDownloaded: (data: boolean) => void;
  startAgainMapOfNeeds: () => Promise<any>;
}

const MapOfNeedsContext = createContext<MapOfNeedsContextInterface>({
  id: "",
  name: "",
  cards: [],
  downloaded: false,
  fetchLatest: () => {
    return Promise.resolve()
  },
  createMapOfNeeds: (data: MapOfNeedsInterface) => {
    return Promise.resolve()
  },
  patchMapOfNeeds: (data: MapOfNeedsInterface) => {
    return Promise.resolve()
  },
  setId: (data: string) => {
  },
  setName: (data: string) => {
  },
  setCards: (data: string[]) => {
  },
  setDownloaded: (data: boolean) => {
  },
  startAgainMapOfNeeds: () => {
    return Promise.resolve()
  },
});

interface Props {
  children: ReactNode
}

const MapOfNeedsProvider = ({children}: Props) => {
  const [id, setId] = useState<string>('');
  const [name, setName] = useState<string>('');
  const [cards, setCards] = useState<string[]>([]);
  const [downloaded, setDownloaded] = useState<boolean>(false);

  const api = useContext(ApiContext);
  const {token, removeJWT} = useJWT();
  const navigate = useNavigate()

  const logout = () => {
    if (token) {
      removeJWT();
      navigate("/logowanie");
    }
  }

  const fetchLatest = async () => {
    return await fetch(`${api.url}map_of_needs/_latest`, {
      headers: {
        'accept': 'application/json',
        'Content-Type': 'application/json',
        'Authorization': `Bearer ${token}`
      },
    }).then(res => {
      if (res.status === 401) {
        logout();
        return;
      }
      return res.json()
    }).then((data) => {
      if (!data) {
        startAgainMapOfNeeds()
        return;
      }
      setId(data.id || "");
      setName(data.name || "");
      setDownloaded(!!data.downloaded);
      setCards(data.cards || [])
    });
  };

  const createMapOfNeeds = async (data: MapOfNeedsInterface) => {
    return await fetch(`${api.url}map_of_needs`, {
      method: 'POST',
      headers: {
        'accept': 'application/json',
        'Content-Type': 'application/json',
        'Authorization': `Bearer ${token}`
      },
      body: JSON.stringify(data),
    }).then(res => {
      if (res.status === 401) {
        logout();
        return;
      }
      return res.json()
    }).then((data) => {
      if (!data) {
        return;
      }

      setId(data.id || "");
      setName(data.name || "");
      setDownloaded(!!data.downloaded);
      setCards(data.cards || [])
      return data;
    });
  };

  const patchMapOfNeeds = async (data: MapOfNeedsInterface) => {
    if (downloaded) {
      return createMapOfNeeds({
        name,
        cards
      })
    }

    return await fetch(`${api.url}map_of_needs/${id}`, {
      method: 'PATCH',
      headers: {
        'accept': 'application/json',
        'Content-Type': 'application/json',
        'Authorization': `Bearer ${token}`
      },
      body: JSON.stringify(data),
    }).then(res => {
      if (res.status === 409) {
        return createMapOfNeeds(data)
      }
      if (res.status === 401) {
        logout();
        return;
      }
      return res.json()
    }).then((data) => {
      setId(data.id || "");
      setName(data.name || "");
      setDownloaded(!!data.downloaded);
      setCards(data.cards || [])
      return data;
    });
  };

  const startAgainMapOfNeeds = async () => {
    return createMapOfNeeds({name: "", cards: []});
  };

  return (
    <MapOfNeedsContext.Provider
      value={{
        id,
        name,
        cards,
        downloaded,
        fetchLatest,
        createMapOfNeeds,
        patchMapOfNeeds,
        setDownloaded,
        setCards,
        setId,
        setName,
        startAgainMapOfNeeds
      }}
    >
      {children}
    </MapOfNeedsContext.Provider>
  );
};

const useMapOfNeeds = (): MapOfNeedsContextInterface => {
  const context = React.useContext(MapOfNeedsContext);
  if (context === undefined) {
    throw new Error('useMapOfNeeds must be used within a MapOfNeedsProvider');
  }
  return context;
};

export {MapOfNeedsProvider, useMapOfNeeds};

import React, {useContext, useState} from 'react';
import HeroLogo from "../../Template/HeroLogo";
import {Alert, Checkbox, FormControlLabel, FormGroup, Radio, RadioGroup} from "@mui/material";
import {Link, useNavigate} from "react-router-dom";
import OrganizationRegisterForm from "./OrganizationRegisterForm";
import IndividualRegisterForm from "./IndividualRegisterForm";
import {Form, Formik, FormikHelpers, FormikValues} from "formik";
import {registerSchema} from "./registerSchema";
import {ApiContext} from "../../App";
import {LoadingButton} from "@mui/lab";
import axios from "axios";

export interface FormValues {
  type: string,
  username: string,
  password: string,
  confirmPassword: string,
  firstName: string,
  lastName: string,
  organisation: string,
  address: string,
  city: string,
  postalCode: string,
  consent: boolean,
}

function Register() {
  const [generalError, setGeneralError] = useState<string | undefined>(undefined);

  const initialValues: FormValues = {
    type: "user",
    username: "",
    password: "",
    confirmPassword: "",
    firstName: "",
    lastName: "",
    organisation: "",
    address: "",
    city: "",
    postalCode: "",
    consent: false,
  };
  const api = useContext(ApiContext);
  const navigate = useNavigate()

  const registerUrl = api.url + "auth/register"

  const handleSubmit = (values: FormikValues, {validateForm, setSubmitting}: FormikHelpers<FormValues>) => {
    validateForm(values).then(async () => {
      const formValues: Partial<FormValues> = {...values};
      delete formValues.confirmPassword;
      delete formValues.consent;

      return await axios.post(registerUrl, formValues)
        .then((response) => {
          setSubmitting(false);
          navigate("/logowanie?created=true");
        })
        .catch((error) => {
          if (error.response.status) {
            setSubmitting(false);
            setGeneralError("Ten adres e-mail jest już zajęty")
          } else {
            setSubmitting(false);
            setGeneralError("Wystąpił błąd, spróbuj ponownie później")
          }
        });
    });
  }

  return (
    <React.Fragment>
      <HeroLogo/>
      <div className="w-full md:w-[60vw] flex flex-col md:justify-center items-center max-md:my-10 max-md:mx-5">
        <div className="w-full md:w-8/12 md:py-8">
          <h2 className="text-2xl mb-6">Zarejestruj się</h2>
          <Formik
            initialValues={initialValues}
            validationSchema={registerSchema}
            validateOnChange={false}
            validateOnBlur={false}
            onSubmit={handleSubmit}
          >
            {({errors, values, handleChange, setFieldValue, isSubmitting}) => {
              return (
                <Form>
                  <div>Kim jesteś?</div>
                  <RadioGroup row className="mb-6" name="type" value={values.type} onChange={handleChange}>
                    <FormControlLabel value="user" control={<Radio/>} label="Osoba indywidualna" className="w-1/2"/>
                    <FormControlLabel value="organisation" control={<Radio/>} label="Organizacja"/>
                  </RadioGroup>

                  {values.type === 'user' &&
                    <IndividualRegisterForm values={values} errors={errors} handleChange={handleChange}/>}

                  {values.type === 'organisation' &&
                    <OrganizationRegisterForm values={values} errors={errors} handleChange={handleChange}/>}

                  <FormGroup className="mt-4">
                    <FormControlLabel
                      control={<Checkbox name="consent" value={values.consent}
                                         onChange={(event, checked) => setFieldValue('consent', checked)}/>}
                      label={
                        <span className={errors.consent && "text-red-600"}>Oświadczam, iż zapoznałem się z <a
                          href="/Polityka_prywatnosci_PESBOX_PLANER.pdf"
                          target="_blank"
                          className="underline text-[#004972]">
                                    Polityką Prywatności Portalu pesbox.pl</a> i ją akceptuję.</span>
                      }/>
                  </FormGroup>

                  {generalError && <Alert className="mt-2" severity="error">{generalError}</Alert>}

                  <div className="mt-4">
                    <LoadingButton
                      loading={isSubmitting}
                      fullWidth
                      loadingPosition="start"
                      variant="contained"
                      type="submit"
                    >
                      Zarejestruj się
                    </LoadingButton>
                  </div>
                </Form>
              );
            }}
          </Formik>

          <div className="mt-8 text-center">
            Jesteś już użytkownikiem PESBOX PLANER ? {" "}
            <Link to={`/logowanie`} className="text-[#1C4F76]">Zaloguj się</Link>
          </div>
        </div>
      </div>
    </React.Fragment>
  );
}

export default Register;

import React, {useContext, useEffect, useState} from 'react';
import {Link, useNavigate} from "react-router-dom";
import Container from "../components/Container";
import {Button, CircularProgress} from "@mui/material";
import ChevronLeftIcon from '@mui/icons-material/ChevronLeft';
import {ApiContext} from "../App";
import {useJWT} from "../contexts/AuthContextProvider";
import {Card} from "./Area";
import NameInput from "../components/NameInput";

export interface AreaInterface {
  id: string;
  name: string;
  order: number;
  colorPrimary: string;
  colorSecondary: string;
  icon: string;
  cards: Card[]
}

function Areas() {
  const api = useContext(ApiContext);
  const {removeJWT, token} = useJWT();
  const navigate = useNavigate()
  const [loading, setLoading] = useState<boolean>(true);
  const [areas, setAreas] = useState<Array<AreaInterface>>([]);

  useEffect(() => {
    fetch(api.url + "areas", {
      headers: {
        'Authorization': `Bearer ${token}`
      },
    }).then(res => {
      if (res.status === 401) {
        removeJWT();
        navigate("/logowanie");
        return;
      }
      return res.json()
    }).then((data: Array<AreaInterface>) => {
      setAreas(data);
      setLoading(false);
    });
  }, [])

  return (
    <Container>
      <div className="md:absolute left-10 top-20 max-md:mt-5">
        <Link to="/">
          <Button variant="contained" color="secondary">
            <ChevronLeftIcon/> Wróć do PESBOX PLANER
          </Button>
        </Link>
      </div>
      <div className="text-5xl max-md:font-bold max-md:text-2xl max-md:my-5 md:my-14 text-center">Zbadaj potrzeby</div>

      <NameInput/>

      {loading &&
        <div className="flex justify-center items-center my-20">
          <CircularProgress size={80}/>
        </div>
      }

      <div className="grid gap-8 max-md:gap-3 md:grid-cols-2 md:mb-14 mb-5 max-md:mx-0 md:mt-10 mt-5">
        {areas.sort((a, b) => a.order - b.order).map(area => {
          return <Link to={area.id} key={area.id}>
            <div style={{backgroundColor: area.colorSecondary}}
                 className="rounded-2xl px-20 py-10 flex flex-col justify-center items-center h-full gap-2">
              <img src={`${api.url}uploads/${area.icon}`} alt=""/>
              <div className="font-black text-white text-xl uppercase text-center tracking-wider">{area.name}</div>
            </div>
          </Link>
        })}
      </div>

      <div className="w-full md:mb-10 mb-4">
        <Link to="/moja-mapa">
          <Button variant="contained" fullWidth>
            Przejdź do mapy potrzeb
          </Button>
        </Link>
      </div>
    </Container>
  );
}

export default Areas;

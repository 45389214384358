import React, {useContext, useState} from 'react';
import HeroLogo from "../../Template/HeroLogo";
import {Alert, TextField, TextFieldProps} from "@mui/material";
import {ApiContext} from "../../App";
import {useJWT} from "../../contexts/AuthContextProvider";
import {useNavigate, useParams} from "react-router-dom";
import {Password} from "../ChangePassword";
import {Form, Formik, FormikHelpers, FormikValues} from "formik";
import * as Yup from 'yup';
import {LoadingButton} from "@mui/lab";

const validationSchema = Yup.object({
  password: Yup.string().trim()
    .required("Wpisz hasło")
    .min(8, "Hasło musi mieć conajmniej 8 znaków")
    .max(64, "Hasło musi mieć maksymalnie 64 znaki"),
  confirmPassword: Yup.string().trim()
    .required("Wpisz hasło")
    .oneOf([Yup.ref('password')], 'Hasła muszą być takie same'),
})

function ForgotPassword() {
  const api = useContext(ApiContext);
  const navigate = useNavigate()
  const [generalError, setGeneralError] = useState<string | undefined>(undefined);
  const {changePassword} = useJWT();
  const [success, setSuccess] = useState(false);
  const {token} = useParams();

  const initialValues: Password = {
    password: "",
    confirmPassword: ""
  }

  const handleSubmit = (values: FormikValues, {validateForm, setSubmitting, setValues}: FormikHelpers<Password>) => {
    setGeneralError("")
    setSubmitting(true);
    setSuccess(false);
    validateForm(values).then(async () => {
      return changePassword(token || "", values.password).then(ok => {
        if (!ok) {
          setGeneralError("Token wygasł, spróbuj przypomnieć hasło jeszcze raz")
          setSubmitting(false);
          return
        }
        setValues(initialValues)
        setSuccess(true);
        setSubmitting(false);
      })
    });
  }

  return (
    <React.Fragment>
      <HeroLogo/>
      <div
        className="w-full md:w-[60vw] flex flex-col md:justify-center items-center max-md:my-10 max-md:mx-5">
        <div className="w-full md:w-8/12">
          <h2 className="text-2xl mb-10">Zmień hasło</h2>

          {success &&
            <Alert className="mb-4" severity="success">Hasło zostało zmienione, możesz się teraz zalogować</Alert>
          }

          <Formik
            initialValues={initialValues}
            validationSchema={validationSchema}
            validateOnChange={false}
            validateOnBlur={false}
            onSubmit={handleSubmit}
          >
            {({errors, values, handleChange, isSubmitting}) => {
              const commonProps: TextFieldProps = {
                fullWidth: true,
                type: "password",
                variant: "outlined",
                style: {
                  backgroundColor: "white"
                },
                onChange: handleChange,
              }

              return (
                <Form className="flex flex-col gap-4">
                  <TextField
                    label="Nowe hasło"
                    name="password"
                    error={!!errors.password}
                    helperText={errors.password}
                    value={values.password}
                    {...commonProps}
                  />
                  <TextField
                    label="Powtórz hasło"
                    name="confirmPassword"
                    error={!!errors.confirmPassword}
                    helperText={errors.confirmPassword}
                    value={values.confirmPassword}
                    {...commonProps}
                  />

                  {generalError && <Alert className="mt-2" severity="error">{generalError}</Alert>}

                  <div className="w-full md:mb-10">
                    <LoadingButton
                      loading={isSubmitting}
                      fullWidth
                      loadingPosition="start"
                      variant="contained"
                      type="submit"
                    >
                      Zmień hasło
                    </LoadingButton>
                  </div>
                </Form>
              )
            }}
          </Formik>
        </div>
      </div>
    </React.Fragment>
  );
}

export default ForgotPassword;

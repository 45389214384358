import React from 'react';
import "./HomeCard.css"
import {Link} from "react-router-dom";

interface Props {
  title: string;
  description: string;
  colorPrimary: string;
  colorSecondary: string;
  url: string;
}

function HomeCard({title, description, colorPrimary, colorSecondary, url}: Props) {
  return (
    <div className="text-center rounded-2xl p-1 flex flex-col" style={{
      backgroundColor: colorPrimary,
    }}>
      <div className="flex items-center">
        <div className="flex-grow font-black text-white text-xl uppercase tracking-wide my-3">
          {title}
        </div>
      </div>
      <div className="bg-white rounded-2xl mt-1 flex-grow mt-2 overflow-hidden">
        <div className="relative pb-[48px] flex justify-between h-full">
          <div className="text-2xl p-2 text-center flex-grow flex justify-center items-center">{description}</div>
          <div className="text-white font-bold text-2xl absolute right-0 bottom-0 hover:opacity-80"
               style={{backgroundColor: colorPrimary}}>
            <Link to={url}>
              <div className="home-card-button w-full h-full">
                Wejdź
              </div>
            </Link>
          </div>
        </div>
      </div>
    </div>
  )
}

export default HomeCard;

import React from 'react';
import '../pages/Home/Welcome.css';
import logos from "../resources/footer-logos.svg"

function Footer() {
  return (
    <footer className="py-5 px-4 bg-[#D9D9D9] lg:flex justify-between">
      <div>
        <p>
          <a className="text-[#1C4F76]" href="/Polityka_prywatnosci_PESBOX_PLANER.pdf" target="_blank">
            Polityka prywatności (PDF)
          </a>
        </p>
        <p>
          Kontakt: <a className="text-[#1C4F76]" href="mailto:kontakt@pesbox.pl">kontakt@pesbox.pl</a>
        </p>

      </div>
      <div className="max-w-[840px] max-lg:mt-3">
        <img src={logos} role="img"
             aria-label="Loga: Fundusze Europejskie - Wiedza Edukacja rozwój, Rzeczpospolita Polska, Inkubator pomysłów, Unia europejska - Europejski Fundusz Społeczny"/>
        <p className="text-xs mt-1">
          Innowacje na ludzką miarę 2. Wsparcie w rozwoju mikroinnowacji w obszarze włączenia społecznego” realizowanego
          w
          ramach Programu Operacyjnego Wiedza Edukacja Rozwój 2014-2020, współfinansowanego ze środków Europejskiego
          Funduszu Społecznego.
        </p>
      </div>
    </footer>
  );
}

export default Footer;

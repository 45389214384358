import * as Yup from 'yup';

export const registerSchema = Yup.object({
  type: Yup.string().trim()
    .required("Wybierz typ konta"),
  username: Yup.string().trim()
    .email("Wpisz poprawny adres e-mail")
    .required("Wpisz adres e-mail"),
  password: Yup.string().trim()
    .required("Wpisz hasło")
    .min(8, "Hasło musi mieć conajmniej 8 znaków")
    .max(64, "Hasło musi mieć maksymalnie 64 znaki"),
  confirmPassword: Yup.string()
    .required("Wpisz hasło")
    .oneOf([Yup.ref('password')], 'Hasła muszą być takie same'),
  firstName: Yup.string().trim()
    .required("Wpisz imię")
    .min(3, "Pole musi mieć conajmniej 3 znaki")
    .max(32, "Pole musi mieć maksymalnie 32 znaki"),
  lastName: Yup.string().trim()
    .required("Wpisz nazwisko")
    .min(3, "Pole musi mieć conajmniej 3 znaki")
    .max(32, "Pole musi mieć maksymalnie 32 znaki"),
  organisation: Yup.string()
    .when("type", {
      is: "organisation",
      then: Yup.string().trim()
        .required("Wpisz nazwę organizacji")
        .min(3, "Pole musi mieć conajmniej 3 znaki")
        .max(32, "Pole musi mieć maksymalnie 32 znaki")
    }),
  address: Yup.string()
    .when("type", {
      is: "organisation",
      then: Yup.string().trim()
        .required("Wpisz adres")
        .min(3, "Pole musi mieć conajmniej 3 znaki")
        .max(48, "Pole musi mieć maksymalnie 48 znaków"),
    }),
  postalCode: Yup.string()
    .when("type", {
      is: "organisation",
      then: Yup.string().trim()
        .required("Wpisz kod pocztowy")
        .matches(/^\d{2}-\d{3}$/, "Podaj kod pocztowy w formacie 00-000"),
    }),
  city: Yup.string().trim()
    .when("type", {
      is: "organisation",
      then: Yup.string().trim()
        .required("Wpisz nazwę miasta")
        .min(3, "Pole musi mieć conajmniej 3 znaki")
        .max(32, "Pole musi mieć maksymalnie 32 znaki"),
    }),
  consent: Yup.boolean().oneOf([true])
});
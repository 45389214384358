import React from 'react';
import '../pages/Home/Welcome.css';
import bg from "../resources/bg2.svg"

function HeroLogo() {
  return (
    <div className="w-[40vw] flex justify-center items-center max-md:hidden"
         style={{
           backgroundImage: `url(${bg})`,
           backgroundSize: 'cover',
           backgroundPosition: 'center',
         }}
    />
  );
}

export default HeroLogo;

import React from 'react';
import logo from '../../resources/logo.svg';
import bg from '../../resources/bg3.svg';
import {Button, useMediaQuery, useTheme} from "@mui/material";
import {Link} from "react-router-dom";
import CheckIcon from '@mui/icons-material/Check';
import {CookieConsent} from "react-cookie-consent";
import Footer from "../../Template/Footer";

function Welcome() {
  const theme = useTheme();
  const matches = useMediaQuery(theme.breakpoints.down("md"));

  return (
    <div className="overflow-x-hidden">
      <div
        className="md:min-w-[100vw] md:min-h-[100vh] py-8 flex flex-col justify-center items-center max-md:px-6 max-md:py-10"
        style={{
          backgroundImage: matches ? 'none' : `url(${bg})`,
          backgroundPosition: "center",
          backgroundRepeat: "no-repeat",
          backgroundSize: "1950px"
        }}>
        <div className="md:w-[750px] flex flex-col justify-center items-center text-base text-justify">
          <img src={logo} className="mb-12" role="img" aria-label="Logo PESBOX PLANER"/>
          <div>
            <p className="my-4">
              Jeśli zadajesz sobie pytania: od czego zacząć, jak zaprojektować i skutecznie rozwijać
              biznes – skorzystaj z <b className="text-l">PESBOX PLANERA</b>.
            </p>
            <p className="my-4">
              <b className="text-l">PESBOX PLANER</b> jest autorskim narzędziem do diagnozowania potrzeb rozwojowych
              organizacji w czterech obszarach biznesu.
            </p>
            <p className="my-4">
              Znajdziesz tu 40 kart. Każda karta to drogowskaz do rozwoju niezbędnych umiejętności biznesowych.
              Możesz z nich zbudować własną Mapę potrzeb i korzystając z opisanych wskazówek zaplanować swoją drogę do
              sukcesu.
            </p>
            <p className="my-4">
              <b className="text-l">PESBOX PLANER</b> to także doskonały asystent doradców biznesowych w pracy z
                  organizacjami i klientami chcącymi rozpocząć działalność, rozwinąć lub wdrożyć
                  zmiany w już prowadzonym biznesie.
            </p>
            <p className="my-4">
            Przeznaczony jest również do samodzielnej pracy dla wszystkich zainteresowanych rozwojem działalności biznesowej.
            </p>
            <p className="mt-4">
            <b className="text-l">PESBOX PLANER</b> pomoże Ci:
              </p>
            <div className="text-center flex flex-col items-center">
              <div className="text-left">
                <p className="my-4 ml-2.5">
                  <CheckIcon className="mr-1 -mt-1"/>
                  <b className="text-xl">Z</b>identyfikować potrzeby rozwojowe,
                </p>
                <p className="my-4 ml-2.5">
                  <CheckIcon className="mr-1 -mt-1"/>
                  <b className="text-xl">Z</b>budować mapę potrzeb,
                </p>
                <p className="mt-4 ml-2.5">
                  <CheckIcon className="mr-1 -mt-1"/>
                  <b className="text-xl">Z</b>aplanować rozwój,
                </p>
                <p className="mt-4 ml-2.5">
                  <CheckIcon className="mr-1 -mt-1"/>
                  <b className="text-xl">O</b>siągnąć sukces.
                </p>
              </div>
            </div>
            <p className="my-4">
            Z PESBOX PLANEREM zrealizujesz swój cel szybciej i bardziej efektywnie niż kiedykolwiek wcześniej.
            </p>

            <div className="flex justify-center items-center gap-[30px] mt-6 md:mt-[60px]">
              <Link to={`rejestracja`}>
                <Button variant="contained">Zarejestruj się</Button>
              </Link>
              <Link to={`logowanie`}>
                <Button variant="outlined">Zaloguj się</Button>
              </Link>
            </div>
          </div>
        </div>
        <CookieConsent
          buttonText="Zamknij"
          cookieName="cookie-consent"
          style={{background: "#2B373BAA"}}
          buttonStyle={{color: "white", backgroundColor: "#1976d2"}}
        >
          Ta strona używa plików cookie w celu usprawnienia i ułatwienia dostępu do serwisu oraz prowadzenia
          danych statystycznych. Dalsze korzystanie z tej witryny oznacza akceptację tego stanu rzeczy.
        </CookieConsent>
      </div>
      <Footer/>
    </div>
  );
}

export default Welcome;

import React, {useContext, useState} from 'react';
import HeroLogo from "../../Template/HeroLogo";
import {Alert, TextField} from "@mui/material";
import {ApiContext} from "../../App";
import {useJWT} from "../../contexts/AuthContextProvider";
import {LoadingButton} from "@mui/lab";

function ForgotPassword() {
  const [email, setUserEmail] = useState<string>('');
  const [emailError, setEmailError] = useState<string | undefined>(undefined);
  const [generalError, setGeneralError] = useState<string | undefined>(undefined);
  const [loading, setLoading] = useState(false);
  const [success, setSuccess] = useState(false);

  const api = useContext(ApiContext);
  const {resetPassword} = useJWT();

  const handleEmailChange = (event: React.ChangeEvent<HTMLInputElement>) => {
    setUserEmail(event.target.value);
    setEmailError(undefined);
    setGeneralError(undefined);
  };
  const handleKeyDown = (event: React.KeyboardEvent<HTMLInputElement>) => {
    if (event.key === "Enter") {
      handleSubmit();
    }
  }

  const handleSubmit = () => {
    setLoading(true);
    setGeneralError(undefined);
    if (!email) {
      setEmailError("Wpisz adres e-mail");
    }

    if (!email || emailError) {
      setLoading(false);
      return;
    }

    if (!api.url || !email) {
      setGeneralError("Wystąpił błąd, spróbuj ponownie później");
      setLoading(false);
      return;
    }

    resetPassword(email).then(ok => {
      if (!ok) {
        setGeneralError("Wystąpił błąd, spróbuj ponownie później");
        setLoading(false);
        return;
      }
      setUserEmail("");
      setSuccess(true);
      setLoading(false);
    })
  }

  return (
    <React.Fragment>
      <HeroLogo/>
      <div
        className="w-full md:w-[60vw] flex flex-col md:justify-center items-center max-md:my-10 max-md:mx-5">
        <div className="w-full md:w-8/12">
          <h2 className="text-2xl mb-3">Zmień hasło</h2>
          <div className="text-neutral-600 mb-5">Wpisz adres powiązany z twoim kontem PESBOX</div>

          {success &&
            <Alert className="mb-4" severity="success">Wysłano e-mail z linkiem do zmiany hasła na podany adres</Alert>
          }

          <div className="w-full">
            <TextField fullWidth
                       label="Adres e-mail"
                       name="email"
                       variant="outlined"
                       error={!!emailError}
                       helperText={emailError}
                       value={email}
                       onChange={handleEmailChange}
                       onKeyDown={handleKeyDown}
            />
          </div>
          {generalError &&
            <Alert className="mt-2" severity="error">{generalError}</Alert>
          }
          <div className="mt-4">
            <LoadingButton
              loading={loading}
              fullWidth
              loadingPosition="start"
              variant="contained"
              onClick={handleSubmit}
            >
              Wyślij link
            </LoadingButton>
          </div>
        </div>
      </div>
    </React.Fragment>
  );
}

export default ForgotPassword;

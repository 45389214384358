import React, {useContext, useEffect, useState} from 'react';
import {Link, useNavigate, useSearchParams} from "react-router-dom";
import Container from "../components/Container";
import {Alert, Button, TextField} from "@mui/material";
import ChevronLeftIcon from '@mui/icons-material/ChevronLeft';
import {ApiContext} from "../App";
import {useJWT} from "../contexts/AuthContextProvider";
import {Form, Formik} from "formik";
import * as Yup from 'yup';
import {LoadingButton} from "@mui/lab";

interface Comment {
  title: string;
  content: string;
}

function Comments() {
  const api = useContext(ApiContext);
  const {removeJWT, token} = useJWT();
  const navigate = useNavigate()
  const [generalError, setGeneralError] = useState<string | undefined>(undefined);
  const [searchParams] = useSearchParams();

  const initialValues: Comment = {
    title: "",
    content: ""
  }

  useEffect(() => {
    fetch(api.url + "areas", {
      headers: {
        'Authorization': `Bearer ${token}`
      },
    }).then(res => {
      if (res.status === 401) {
        removeJWT();
        navigate("/logowanie");
        return;
      }
      return res.json()
    })
  }, [])

  return (
    <Container>
      <div className="absolute left-10 top-20">
        <Link to="/">
          <Button variant="contained" color="secondary">
            <ChevronLeftIcon/> Wróć do PESBOX PLANER
          </Button>
        </Link>
      </div>
      <div className="text-5xl max-md:font-bold max-md:text-2xl max-md:my-5 md:my-14 text-center">Zgłoś uwagi</div>
      {searchParams.has("created") &&
        <Alert className="mb-4" severity="success">Twoje uwagi zostały przesłane</Alert>
      }
      <Formik
        initialValues={initialValues}
        validationSchema={Yup.object({
          title: Yup.string().trim()
            .required("Wpisz tytuł"),
          content: Yup.string().trim()
            .required("Wpisz opis")
        })}
        validateOnChange={false}
        validateOnBlur={false}
        onSubmit={(values, {validateForm, setSubmitting, setValues}) => {
          validateForm(values).then(async () => {
            return await fetch(api.url + "contact/feedback", {
              method: 'POST',
              headers: {
                'accept': 'application/json',
                'Content-Type': 'application/json',
                'Authorization': `Bearer ${token}`
              },
              body: JSON.stringify(values),
            }).then(res => {
              if (res.ok) {
                setSubmitting(false);
                navigate("/uwagi?created=true")
                setValues(initialValues)
              } else {
                setSubmitting(false);
                setGeneralError("Wystąpił błąd, spróbuj ponownie później")
              }
            });
          });
        }}
      >
        {({errors, values, handleChange, isSubmitting}) => {
          return (
            <Form>
              <div className="flex flex-col gap-6 md:w-[500px]">
                <TextField fullWidth
                           label="Tytuł"
                           name="title"
                           variant="outlined"
                           style={{
                             backgroundColor: "white"
                           }}
                           error={!!errors.title}
                           helperText={errors.title}
                           value={values.title}
                           onChange={handleChange}
                />
                <TextField fullWidth
                           label="Opis"
                           name="content"
                           variant="outlined"
                           multiline
                           rows={6}
                           style={{
                             backgroundColor: "white"
                           }}
                           error={!!errors.content}
                           helperText={errors.content}
                           value={values.content}
                           onChange={handleChange}
                />

                {generalError && <Alert className="mt-2" severity="error">{generalError}</Alert>}

                <div className="w-full md:mb-10 mb-4">
                  <LoadingButton
                    loading={isSubmitting}
                    fullWidth
                    loadingPosition="start"
                    variant="contained"
                    type="submit"
                  >
                    Wyślij
                  </LoadingButton>
                </div>
              </div>
            </Form>
          )
        }}
      </Formik>
    </Container>
  );
}

export default Comments;

import React, {ReactNode} from 'react';
import bg from '../resources/bg.svg';
import {useMediaQuery, useTheme} from "@mui/material";

interface Props {
  children: ReactNode,
  hideBg?: boolean;
  limitSize?: boolean;
}

function Container({children, hideBg, limitSize = true}: Props) {
  const theme = useTheme();
  const showBg = useMediaQuery(theme.breakpoints.up('md')) && !hideBg;

  return (
    <div className="w-full flex flex-col justify-center items-center overflow-hidden" style={{
      backgroundImage: showBg ? `url(${bg})` : "none",
      backgroundSize: 'cover',
      backgroundPosition: 'center',
    }}>
      <div className={`${limitSize ? "max-w-[775px]" : "w-full"} mx-auto mx-5`}>
        {children}
      </div>
    </div>
  );
}

export default Container;

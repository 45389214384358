import React from 'react';
import {useJWT} from "../../contexts/AuthContextProvider";
import Home from "./Home";
import Welcome from "./Welcome";

function Start() {
  const {token} = useJWT();
  if (token) {
    return <Home/>;
  } else {
    return <Welcome/>
  }
}

export default Start;

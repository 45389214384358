import React, {useEffect, useState} from 'react';
import {Card} from "../pages/Area";
import ChevronLeftIcon from '@mui/icons-material/ChevronLeft';
import ChevronRightIcon from '@mui/icons-material/ChevronRight';
import {Button} from "@mui/material";
import CardComponent from "./CardComponent";
import {useMapOfNeeds} from "../contexts/MapOfNeedsProvider";
import NeedsContainer from "./NeedsContainer";
import {AreaInterface} from "../pages/Areas";
import CardModal from "./CardModal";

interface Props {
  allCards: Card[];
  area: AreaInterface;
}

function CardCarousel({allCards, area}: Props) {
  const [sortedCards, setSortedCards] = useState<Card[]>(allCards.sort((a, b) => {
    return a.order - b.order
  }))
  const [open, setOpen] = useState(false);
  const {cards} = useMapOfNeeds();

  const handleOpen = () => {
    setOpen(true);
  };
  const handleClose = () => setOpen(false);

  let middleIndex = Math.floor(allCards.length / 2);

  const selectedCard = sortedCards[middleIndex];

  const selected = selectedCard && cards.includes(selectedCard.id);

  const prepareCards = () => {
    middleIndex = Math.floor(allCards.length / 2);
    const arr = [...allCards.sort((a, b) => {
      return a.order - b.order
    })]
    for (let i = 0; i < middleIndex; i++) {
      arr.unshift(arr.pop() as Card)
    }

    setSortedCards(arr)
  }

  useEffect(() => {
    prepareCards()
  }, [allCards])

  const handleNext = () => {
    const arr = [...sortedCards];
    arr.push(arr.shift() as Card)
    setSortedCards(arr);
  };

  const handleBack = () => {
    const arr = [...sortedCards];
    arr.unshift(arr.pop() as Card)
    setSortedCards(arr);
  };

  const handleKeyDown = (e: KeyboardEvent) => {
    if (e.key === "ArrowRight") {
      handleNext();
    }
    if (e.key === "ArrowLeft") {
      handleBack();
    }
  }

  React.useEffect(() => {
    window.addEventListener('keydown', handleKeyDown);

    return () => {
      window.removeEventListener('keydown', handleKeyDown);
    };
  }, [sortedCards]);

  if (!selectedCard) {
    return null;
  }

  return (
    <div>
      <div className="flex justify-center">
        {sortedCards.map((card, index) => {
          return (
            <CardComponent key={card.id}
                           card={card}
                           handleOpen={handleOpen}
                           isMiddle={index === middleIndex}
                           style={{
                             width: index === middleIndex ? "280px" : "250px",
                             marginTop: index < middleIndex ? 20 * (middleIndex - index) : 20 * (index - middleIndex),
                             marginRight: index < middleIndex ? -200 : 0,
                             marginLeft: index > middleIndex ? -200 : 0,
                             zIndex: index > middleIndex ? -(index - middleIndex) : 0,
                             cursor: index === middleIndex ? "pointer" : "default",
                           }}/>
          )
        })}
      </div>

      <div className="flex justify-center mt-5 gap-2">
        <Button onClick={handleBack} style={{color: "black"}} aria-label="Poprzednia karta">
          <ChevronLeftIcon/>
        </Button>
        <Button onClick={handleOpen} variant="outlined" color="primary">
          Powiększ kartę
        </Button>
        <Button onClick={handleNext} style={{color: "black"}} aria-label="Następna karta">
          <ChevronRightIcon/>
        </Button>
      </div>

      <div className="flex flex-col items-center">
        <div className="text-center text-xl my-5">
          Moje potrzeby z obszaru {area.order}
        </div>
        <NeedsContainer areaCards={sortedCards} area={area}/>
      </div>

      <CardModal selected={selected} selectedCard={selectedCard} nextHandler={handleNext} backHandler={handleBack}
                 handleClose={handleClose} open={open}/>
    </div>
  );
}

export default CardCarousel;
import React, {useEffect, useState} from 'react';
import {Button} from "@mui/material";
import DeleteOutlineIcon from '@mui/icons-material/DeleteOutline';
import {useMapOfNeeds} from "../contexts/MapOfNeedsProvider";
import {Card} from "../pages/Area";
import NeedButton from "./NeedButton";
import {AreaInterface} from "../pages/Areas";

interface Props {
  areaCards: Card[];
  area: AreaInterface;
}

function NeedsContainer({areaCards, area}: Props) {
  const {cards, patchMapOfNeeds, name} = useMapOfNeeds();

  const [selectedAreaCards, setSelectedAreaCards] = useState<Card[]>([]);

  const handleRemoveCards = () => {
    const selectedAreaCardsId = selectedAreaCards.map(el => el.id);

    patchMapOfNeeds({
      name,
      cards: cards.filter(cardId => !selectedAreaCardsId.includes(cardId))
    })
  }

  useEffect(() => {
    setSelectedAreaCards(areaCards.filter((card) => cards.includes(card.id)))
  }, [cards, areaCards, area])

  return (
    <div className="flex justify-center items-center gap-2">
      <div
        className="border border-neutral-400 rounded-md h-full p-2 flex flex-wrap gap-2 min-w-[180px] min-h-[60.5px]">
        {selectedAreaCards.sort((a, b) => {
          return a.order - b.order
        }).map((card) => {
          return <NeedButton key={card.id} card={card} area={area} showDeleteButton={true}/>
        })}
      </div>
      <Button variant="contained" color="error" onClick={handleRemoveCards}>
        <div className="flex flex-col justify-center items-center">
          <DeleteOutlineIcon/>
          Usuń karty
        </div>
      </Button>
    </div>
  );
}

export default NeedsContainer;
import React, {useContext, useState} from 'react';
import {Link, useNavigate, useSearchParams} from "react-router-dom";
import Container from "../components/Container";
import {Alert, Button, TextField} from "@mui/material";
import ChevronLeftIcon from '@mui/icons-material/ChevronLeft';
import {ApiContext} from "../App";
import {useJWT} from "../contexts/AuthContextProvider";
import {Form, Formik} from "formik";
import * as Yup from 'yup';
import {LoadingButton} from "@mui/lab";

export interface Password {
  password: string;
  confirmPassword: string;
}

function ChangePassword() {
  const api = useContext(ApiContext);
  const {token} = useJWT();
  const navigate = useNavigate()
  const [generalError, setGeneralError] = useState<string | undefined>(undefined);
  const [searchParams] = useSearchParams();

  const initialValues: Password = {
    password: "",
    confirmPassword: ""
  }

  return (
    <Container>
      <div className="absolute left-10 top-20">
        <Link to="/">
          <Button variant="contained" color="secondary">
            <ChevronLeftIcon/> Wróć do PESBOX PLANER
          </Button>
        </Link>
      </div>
      <div className="text-5xl max-md:font-bold max-md:text-2xl max-md:my-5 md:my-14 text-center">Zmień hasło</div>
      {searchParams.has("created") &&
        <Alert className="mb-4" severity="success">Twoje hasło zostało zmienione</Alert>
      }
      <Formik
        initialValues={initialValues}
        validationSchema={Yup.object({
          password: Yup.string().trim()
            .required("Wpisz hasło")
            .min(8, "Hasło musi mieć conajmniej 8 znaków")
            .max(64, "Hasło musi mieć maksymalnie 64 znaki"),
          confirmPassword: Yup.string()
            .required("Wpisz hasło")
            .oneOf([Yup.ref('password')], 'Hasła muszą być takie same'),
        })}
        validateOnChange={false}
        validateOnBlur={false}
        onSubmit={(values, {validateForm, setSubmitting, setValues}) => {
          setGeneralError("")
          validateForm(values).then(async () => {
            return await fetch(api.url + "profile", {
              method: 'PATCH',
              headers: {
                'accept': 'application/json',
                'Content-Type': 'application/json',
                'Authorization': `Bearer ${token}`
              },
              body: JSON.stringify({password: values.password}),
            }).then(res => {
              if (res.ok) {
                setSubmitting(false);
                navigate("/zmien-haslo?created=true")
                setValues(initialValues)
              } else {
                setSubmitting(false);
                setGeneralError("Wystąpił błąd, spróbuj ponownie później")
              }
              return res.json()
            });
          });
        }}
      >
        {({errors, values, handleChange, isSubmitting}) => {
          return (
            <Form>
              <div className="flex flex-col gap-6 md:w-[500px]">
                <TextField fullWidth
                           type="password"
                           label="Nowe hasło"
                           name="password"
                           variant="outlined"
                           style={{
                             backgroundColor: "white"
                           }}
                           error={!!errors.password}
                           helperText={errors.password}
                           value={values.password}
                           onChange={handleChange}
                />
                <TextField fullWidth
                           type="password"
                           label="Powtórz hasło"
                           name="confirmPassword"
                           variant="outlined"
                           style={{
                             backgroundColor: "white"
                           }}
                           error={!!errors.confirmPassword}
                           helperText={errors.confirmPassword}
                           value={values.confirmPassword}
                           onChange={handleChange}
                />

                {generalError && <Alert className="mt-2" severity="error">{generalError}</Alert>}

                <div className="w-full md:mb-10 mb-4">
                  <LoadingButton
                    loading={isSubmitting}
                    fullWidth
                    loadingPosition="start"
                    variant="contained"
                    type="submit"
                  >
                    Zmień hasło
                  </LoadingButton>
                </div>
              </div>
            </Form>
          )
        }}
      </Formik>
    </Container>
  );
}

export default ChangePassword;

import React, {useState} from 'react';
import {TextField} from "@mui/material";
import {useMapOfNeeds} from "../contexts/MapOfNeedsProvider";

function NameInput() {
  const {patchMapOfNeeds, cards, name} = useMapOfNeeds();
  const [nameState, setName] = useState<string>(name);

  const handleBlur = () => {
    patchMapOfNeeds({
      name: nameState,
      cards
    });
  }
  const handleNameChange = (e: React.ChangeEvent<HTMLInputElement>) => {
    setName(e.target.value);
  }

  return (
    <TextField fullWidth
               label="Nazwa projektu / organizacji"
               name="name"
               variant="outlined"
               style={{
                 backgroundColor: "white"
               }}
               value={nameState}
               onChange={handleNameChange}
               onBlur={handleBlur}
    />
  );
}

export default NameInput;
